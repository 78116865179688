import React from 'react'
import { ETitleType, Title } from '@/components/ui/Title/Title'
import { Text } from '@/components/ui/Text/Text'

export enum EMessageType {
  'success',
  'error',
  'warning'
}

export interface IMessage {
  type: EMessageType
  title: string
  message?: string
  displayLimitLink?: boolean
}

const ICON_TYPE = {
  [EMessageType.success]: {
    src: 'delete-confirmation.svg',
    alt: 'success icon'
  },
  [EMessageType.error]: {
    src: 'catError.svg',
    alt: 'error'
  },
  [EMessageType.warning]: {
    src: 'catError.svg',
    alt: 'warning'
  }
}

const Message = ({ type, title, message }: IMessage) => {
  return (
    <>
      {type === EMessageType.success ? (
        <div className="flex flex-col items-center justify-center h-full w-full">
          <img src={`/assets/icons/${ICON_TYPE[type].src}`} alt={ICON_TYPE[type].alt} className="w-50 my-8" />
          <Title title={title} type={ETitleType.H3} className="font-bold text-center text-32" />
          {message && <Text text={message} extraClassName="text-center text-gray-500 text-14 -mt-2" />}
        </div>
      ) : (
        <div className="flex flex-col justify-between w-full h-full p-4">
          <div className="flex flex-col items-center">
            <Title title={title} type={ETitleType.H3} className="font-bold text-center" />
            {message && <Text text={message} extraClassName="text-center text-gray-500 text-14 -mt-2" />}
            <img src={`/assets/icons/${ICON_TYPE[type].src}`} alt={ICON_TYPE[type].alt} className="w-58 my-8" />
          </div>
        </div>
      )}
    </>
  )
}

export default Message
