import RequestHelper, { IApiResponse } from '@/services/RequestHelper'
import { FETCH_URL } from '../common'
import { IFile } from '../../common'

interface IFetchTransferRequest {
  transferKey: string
  password?: string
}

export interface IFetchTransferResponse {
  zip: IFile | null
  files: IFile[]
  // recipient: string | null
}

export const fetchTransfer = ({
  transferKey,
  password
}: IFetchTransferRequest): Promise<IApiResponse<IFetchTransferResponse | null>> => {
  return RequestHelper.get<IFetchTransferResponse>({ url: FETCH_URL(transferKey), headers: { 'x-password': password } })
}
