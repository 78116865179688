import { Button } from '@transverse/digitalsystems-library'
import { useRouter } from 'next/router'

interface IReportKey {
  downloadKey: string
}

const ReportCard = ({ downloadKey }: IReportKey) => {
  const router = useRouter()

  return (
    <div className="flex justify-center items-center border border-gray-100 rounded-8 text-14 shadow-[0_2px_4px_rgb(0,0,0,0.4)] shadow-gray-100">
      <img src="/assets/images/avatar_report.svg" alt="report" className="w-1/4 h-fit" />

      <div className="flex flex-col m-4">
        <div className="flex flex-col">
          <span className="font-semibold">Fichiers douteux ?</span>
          <p className="my-2">Vous pouvez signaler un abus envers la personne qui vous a envoyé ces fichiers.</p>
        </div>
        <Button
          label="Signaler un abus"
          variant="tertiary"
          size="m"
          className="w-fit"
          onClick={() => router.push(`/report/information/${downloadKey}`)}
        />
      </div>
    </div>
  )
}

export default ReportCard
