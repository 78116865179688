import Password from '@/components/ui/Input/Password'

type DownloadType = {
  password: string
  setPassword: (password: string) => void
  onSubmit: () => void
  message: string
  error?: string
}

const PasswordForm = ({ password, setPassword, onSubmit, message, error }: DownloadType) => {
  return (
    <div>
      <p className="font-semibold mb-6">{message}</p>
      <Password
        name="password_upload"
        value={password}
        placeholder="Mot de passe"
        onChange={(e) => setPassword(e.target.value)}
        onSubmit={onSubmit}
        error={!!error}
      />
      {error && <span className="text-12 text-red">{error}</span>}
    </div>
  )
}

export default PasswordForm
