import React from 'react'
import ReportCard from '@/components/ui/Card/ReportCard'
import { IFile } from '@/services/fetchers/common'
import FileList from '@/components/ui/FileList/FileList'
import { Spinner } from '@transverse/digitalsystems-library'

interface IDownload {
  files: IFile[]
  onDownload: (file: IFile) => void
  downloadKey: string
}

const Download = ({ files, onDownload, downloadKey }: IDownload) => {
  const title = `Voici ${files.length} fichier${files.length > 1 ? 's' : ''} que vous pouvez télécharger`

  return (
    <>
      {files.length > 0 ? (
        <div className="flex flex-col justify-between h-full text-14">
          <div>
            <p className="font-bold text-20">Récupérez vos fichiers</p>
            <p className="font-semibold mt-10 pb-4 border-b border-1 border-gray-100">{title}</p>

            <FileList files={files} onClick={onDownload} icon="download-line" className="mb-8" />
          </div>

          <ReportCard downloadKey={downloadKey} />
        </div>
      ) : (
        <div className="flex h-full justify-center items-center">
          <Spinner size={90} className="text-blue" />
        </div>
      )}
    </>
  )
}

export default Download
