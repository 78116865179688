// convert bytes to Mo
export const bytesToMo = (bytes: number) => parseFloat((bytes / (1024 * 1024)).toFixed(2))

export const formatSize = (size: number) => {
  const logSize = size > 0 ? Math.log(size) : 0
  const i = Math.floor(logSize / Math.log(1024))
  return parseFloat((size / Math.pow(1024, i)).toFixed(2)) + ' ' + ['o', 'ko', 'Mo', 'Go', 'To'][i]
}

export const formatNumber = (n: number) => (Math.round(n * 100) / 100).toFixed(2)
