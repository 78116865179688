import clsx from 'clsx'

import { ETheme, ETextColor } from '@/shared/types'

interface IText {
  text: string
  extraClassName?: string
  color?: ETextColor
  theme?: ETheme
}

export const Text = ({ text, extraClassName, color = ETextColor.DEFAULT, theme = ETheme.light }: IText) => {
  return <div className={clsx('text-left', extraClassName, theme === ETheme.light ? color : 'text-white')}>{text}</div>
}
