import clsx from 'clsx'
import React, { useState } from 'react'

export interface IPassword {
  name: string
  placeholder: string
  value: string
  onChange?: (e: any) => void
  hookFormConf?: any
  onSubmit?: () => void
  error?: boolean
}

const Password = ({ placeholder, hookFormConf, onChange, name, value, onSubmit, error = false }: IPassword) => {
  const [displayText, setDisplayText] = useState<boolean>(false)
  const eyeOpen = '/assets/icons/eye.svg'
  const eyeClose = '/assets/icons/closed-eye.svg'

  const handleOnChange = (event) => {
    if (onChange) {
      onChange(event)
    }

    if (hookFormConf) {
      hookFormConf.onChange(event)
    }
  }

  const handleSubmit = (event) => {
    const keyCode = event.keyCode || event.which

    if (onSubmit && keyCode === 13) {
      onSubmit()
    }
  }

  return (
    <div className="relative flex items-center mb-4">
      <div className={clsx('rounded-8 w-full', error ? 'border-2 border-red' : 'border border-gray-200')}>
        <input
          className={clsx(
            'w-full py-[12px] px-4 rounded-8 active:outline-1 outline-gray-900 focus:border-transparent',
            value && value.length > 0 && !displayText && 'laptop:tracking-wider laptop:font-bold'
          )}
          name={name}
          type={displayText ? 'text' : 'password'}
          placeholder={placeholder}
          onKeyUp={handleSubmit}
          {...hookFormConf}
          onChange={handleOnChange}
        />

        <span
          role="button"
          onClick={(e) => {
            e.preventDefault()
            setDisplayText(!displayText)
          }}
          onKeyDown={(e) => {
            e.preventDefault()
            setDisplayText(!displayText)
          }}
          className={clsx('top-[18px] absolute right-4', displayText && 'top-[16px]')}
          tabIndex={0}
        >
          <img src={!displayText ? eyeOpen : eyeClose} alt="icône mot de passe" />
        </span>
      </div>
    </div>
  )
}

export default Password
