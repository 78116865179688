import clsx from 'clsx'
import { formatSize } from '@/shared/format'
import React from 'react'
import { IFile } from '@/services/fetchers/common'
import { Icon, IconsId } from '@transverse/digitalsystems-library'

interface IFileList {
  files: IFile[]
  onClick: (file: IFile) => void
  icon?: string
  className?: string
}

const FileList = ({ files, onClick, icon = 'close-line', className }: IFileList) => {
  return (
    <div className={className}>
      {files.map((file, index: number) => (
        <div
          key={index}
          className={clsx(
            'flex items-center justify-between py-2',
            index + 1 !== files.length && 'border-b border-1 border-gray-100'
          )}
        >
          <div className="flex flex-col w-full">
            <span className="break-all w-full text-14 text-gray-700 font-semibold">{file.path}</span>
            <span className="text-12 text-gray-600">{formatSize(file.size ?? 0)}</span>
          </div>

          <div className="flex items-center h-full" onClick={() => onClick(file)} role="none">
            <Icon
              name={icon as IconsId}
              className="text-24 ml-4 p-2 cursor-pointer bg-white duration-500 hover:bg-gray-50 hover:rounded-full"
            />
          </div>
        </div>
      ))}
    </div>
  )
}

export default FileList
