import RequestHelper, { IApiResponse } from '../../../RequestHelper'
import { DOWNLOAD_URL } from '../common'

interface IDownloadFileRequest {
  transferKey: string
  path: string
  password?: string
}

interface IDownloadFileResponse {
  url: string
}

export const downloadFile = ({
  transferKey,
  path,
  password
}: IDownloadFileRequest): Promise<IApiResponse<IDownloadFileResponse | null>> => {
  return RequestHelper.get<IDownloadFileResponse>({
    url: DOWNLOAD_URL,
    params: { transferKey, path },
    headers: { 'x-password': password }
  })
}
